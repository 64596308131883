import CoreAPIService from "./CoreAPIService";
import { API_ENDPOINTS, checkAccessToken } from "../utils";
import axios from 'axios';

const BASE_API_ENDPOINT =
  process.env.REACT_APP_PUBLIC_NETWORK_TYPE === 'testnet'
    ? process.env.REACT_APP_PUBLIC_API_TESTNET_BACKEND_URL
    : process.env.REACT_APP_PUBLIC_API_MAINNET_BACKEND_URL;
class GiftsService {
  getGiftsCategory = async (pageSize: number, pageNumber: number) =>
    CoreAPIService.get<GiftResponse>(
      `${API_ENDPOINTS.PRIVATE.GETGIFTSCATEGORY}/${pageSize}/${pageNumber}`
    );
  addGiftsCategory = async (payload: FormData) =>
    CoreAPIService.post<ReactionResponse>(
      `${API_ENDPOINTS.PRIVATE.ADDGIFTSCATEGORY}`,
      payload
    );
  deleteGiftsCategory = async (giftCategoryId: number) =>
    CoreAPIService.delete<ReactionResponse>(
      `${API_ENDPOINTS.PRIVATE.DELETEGIFTSCATEGORY}/${giftCategoryId}`
    );
  getGiftsList = async (pageSize: number, pageNumber: number) =>
    CoreAPIService.get<GiftResponse>(
      `${API_ENDPOINTS.PRIVATE.GETGIFTSLIST}/${pageSize}/${pageNumber}`
    );
  addGiftsList = async (payload: FormData) =>
    CoreAPIService.post<ReactionResponse>(
      `${API_ENDPOINTS.PRIVATE.ADDGIFTSLIST}`,
      payload
    );
  updateGiftsList = async (payload: FormData, giftId: number) =>
    CoreAPIService.put<ReactionResponse>(
      `${API_ENDPOINTS.PRIVATE.UPDATEGIFTSLIST}/${giftId}`,
      payload
    );
  deleteGiftsList = async (giftId: number) =>
    CoreAPIService.delete<ReactionResponse>(
      `${API_ENDPOINTS.PRIVATE.DELETEGIFTSLIST}/${giftId}`
    );
}
// eslint-disable-next-line import/no-anonymous-default-export
export default new GiftsService();

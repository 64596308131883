import { Button, Select, Spinner, Typography } from "../../../Atoms";
import { FormInput as Input } from "../..";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { dataForm, version } from "./utils";
import { AddEnvironmentModalProps } from "./interface";
import { useGifts } from "../../../../hooks/api/useGifts";
import { useDispatch, useSelector } from "react-redux";
import { setUploadGiftIcon, setUploadGiftImage } from "../../../../store";
import { toast } from "react-toastify";
import { getSpinnerSize, getSpinnerStokeColor } from "../../../Atoms/Button/utils";
export const AddGiftsModal = ({ save, giftListData, editData, isEditing, setIsEditing, isLoading }: AddEnvironmentModalProps) => {
  const [selectData, setSelectData] = useState<any>(editData ? { label: editData?.categoryName, value: editData?.categoryName } : "");
  const { giftCategoryAsync } = useGifts()
  const [categoryList, setCategoryList] = useState([])
  const { handleSubmit, control, register } = useForm();
  const [pageNumber, setPageNumber] = useState(1);
  const [iconUpload, setIconUpload] = useState(false)
  const [imageUpload, setImageUpload] = useState(false)
  const pageSize = 10
  const dispatch = useDispatch()
  const giftIcon = useSelector((state: any) => state.analyticsSidebar.uploadGiftIcon)
  const giftImage = useSelector((state: any) => state.analyticsSidebar.uploadGiftImage)
  const getGiftCategory = async (pageNumber: number) => {
    try {
      const response = await giftCategoryAsync(pageNumber, pageSize)
      if (response?.success && response?.data) {
        setCategoryList(response?.data && response?.data?.list && response?.data?.list.length > 0 && response?.data?.list.map((data: any) => data?.name))
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    try {
      getGiftCategory(pageNumber)
    } catch (error) {
      console.log(error)
    }
  }, [])

  const onSubmit = (data: any) => {
    if (!isEditing ? data?.name !== "" && data?.price !== "" && data?.gift_icon.length > 0 && data?.gift_image.length > 0 && selectData : editData?.name && editData?.giftPrice) {
      const payload = {
        giftName: data.name || editData?.name,
        categoryName: selectData?.value || '',
        giftPrice: data.price || editData?.giftPrice,
        // giftIcon: data["gift_icon"]?.[0],
        // giftImage: data["gift_image"]?.[0],
        ...(data["gift_icon"]?.length > 0 && { giftIcon: data["gift_icon"]?.[0] }),
        ...(data["gift_image"]?.length > 0 && { giftImage: data["gift_image"]?.[0] }),
      };
      save?.(dataForm(payload));
    } else {
      toast.error("Please fill all data to proceed!")
    }
  };
  const labelTextClassName = [
    "mb-1 font-semibold text-neutral-100 dark:text-neutral-600",
  ].join(" ");

  // const handleIconChange = (e: any) => {
  //   dispatch(setUploadGiftIcon(e.target.files[0]));  // Set the new file (binary)
  //   setIconUpload(true);
  // };

  // const handleImageChange = (e: any) => {
  //   dispatch(setUploadGiftImage(e.target.files[0]));  // Set the new file (binary)
  //   setImageUpload(true);
  // };

  const spinnerClasses = [
    getSpinnerStokeColor("neon"),
    getSpinnerSize("small"),
  ].join(" ");

  return (
    <form
      className="md:!w-[650px] max-h-[500px] overflow-auto overflow-y-auto overflow-x-hidden"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-6 px-4 ">
        <Input
          required
          className="w-full dark:text-neutral-400"
          control={control}
          label="Gift Name"
          placeholder="name"
          name="name"
          type="text"
          maxLength={20}
          defaultValue={isEditing ? editData?.name : ""}
        />
      </div>
      <div className="mb-6 px-4 ">
        <Select
          label="Gift Category"
          options={categoryList.map((category: any) => ({ label: category, value: category }))}
          value={selectData || { label: "Select Gift Category", value: "" }}
          setSelectedCollectionAsset={(value) => setSelectData(value)}
        />
      </div>
      <div className="mb-6 px-4 ">
        <Input
          required
          className="w-full dark:text-neutral-400"
          control={control}
          label="Gifts Price"
          placeholder="Gifts Price"
          name="price"
          type="number"
          min={1}
          defaultValue={isEditing ? editData?.giftPrice : ""}
        // maxLength={200}
        />
      </div>
      <div className="-mx-1  flex flex-wrap ">
        <div className="mb-4 w-full px-4 sm:mb-0 sm:w-1/2  md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"Gift Icon"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            // required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("gift_icon")}
            // onChange={handleIconChange}
          />
          {isEditing && editData?.giftIcon && !iconUpload && (
            <p className="text-neutral-300 mt-1">{`${editData.giftIcon.slice(0, 20)}...${editData.giftIcon.slice(-10)}`}</p>
          )}
        </div>
        <div className="w-full mb-4 px-4 sm:w-1/2 md:w-1/2">
          <Typography
            variant="condensed"
            size="caption"
            className={labelTextClassName}
          >
            {"Gift Image"}
            {<span className="font-bold text-[#FF0000]">*</span>}
          </Typography>
          <input
            // required
            className="w-full dark:text-neutral-400"
            type="file"
            accept="image/*"
            {...register("gift_image")}
            // onChange={handleImageChange}
          />
          {isEditing && editData?.giftImage && !imageUpload && (
            <p className="text-neutral-300 mt-1">{`${editData.giftImage.slice(0, 20)}...${editData.giftImage.slice(-10)}`}</p>
          )}
        </div>
      </div>

      <div className="mb-6 w-full flex justify-end px-4 py-4">
        <Button
          color="primary"
          size="small"
          variant="solid"
          type="submit"
          className="w-[200px] !px-10 !bg-[#3b7ddd]"
        >
          {isLoading ?
            <Spinner
              className={spinnerClasses}
            />
            :
            "Save change"
          }
        </Button>
      </div>
    </form>
  );
};

export const API_ENDPOINTS = {
  AUTH: {
    LOGIN: '/auth/sign-in',
  },
  PRIVATE: {
    USER: '/users',
    ENVIRONMENTS: '/environments',
    MUSEUMS: '/museums',
    ITEMS: '/items',
    ITEMS_TOGGLE_DELETE_ITEM: '/items/toggle-delete-item',
    SERIES: '/series',
    SERIES_ADD: '/series/add-series',
    SERIES_DELETE: '/series/delete',
    BACKGROUNDFILES: '/background-files',
    ANIMATIONS: '/animations',
    REACTIONS: '/reactions',
    ALLWORLDS: '/allWorlds',
    // GETALLWORLDS: '/item/v3/get-xana-universe',
    UPDATEWORLDS: '/allWorlds/update-worlds-order',
    UPDATESTATUSWORLDS: '/allWorlds/updateWorldStatus',
    UPDATELOBBYWORLDS:'/item/xana-lobby-world-order',
    GETLOBBYWORLDS: '/item/get-all-worlds-for-xana-lobby',
    UPDATELOBBYWORLD: '/item/add-xana-lobby-world',
    // https://api-test.xana.net/item/delete-xana-lobby-asset/:assetId
    DELETELOBBYWORLD: '/item/delete-xana-lobby-asset',
    USERCUSTOMEVENTS: '/userCustomEvents',
    USERCUSTOMEVENTS_UPDATE_APPROVALSTATUS: '/userCustomEvents/update-approvalStatus',
    ORDEREVENT: '/userCustomEvent/update-event-order',
    FEATURES: '/features',
    FEATURES_DELETE: '/features/delete',
    FEATURES_ADD: '/features/add-feature',
    SET_ADD: '/features/add-set',
    SET_DELETE: '/features/delete-set',
    ANALYTICS: '/analytical/get-all-world-logs',
    ANALYTICSUSER: '/analytical/get-all-user-logs',
    ANALYTICSFILTER: '/analytical/get-user-visit-logs',
    ADDCATEGORY: "/allWorlds/add-xana-world-category",
    GETCATEGORY: "/item/get-xana-world-category",
    ADDTEXT: "/item/set-xana-lobby-dynamic-data",
    FEATUREDWORLDS : '/allWorlds/update-featured-space',
    EDITIEM : "/item/update-item",
    ADDGIFTSCATEGORY: '/items/add-gift-category',
    GETGIFTSCATEGORY: '/items/gift-category-list',
    DELETEGIFTSCATEGORY: '/items/delete-gift-category-by-Id',
    ADDGIFTSLIST: '/items/add-gifts',
    GETGIFTSLIST: '/items/gifts-list',
    UPDATEGIFTSLIST: '/items/edit-gift-by-Id',
    DELETEGIFTSLIST: '/items/delete-gift-by-Id',
  },
} as const;


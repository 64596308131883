import React from "react";
import { Button, Spinner } from "../../../Atoms";
import { ProceedModalProps } from "./interface";
import { getSpinnerSize, getSpinnerStokeColor } from "../../../Atoms/Button/utils";

export const DeleteModal = ({ proceed, isLoading }: ProceedModalProps) => {
  const spinnerClasses = [
    getSpinnerStokeColor("neon"),
    getSpinnerSize("small"),
  ].join(" ");
  return (
    <>
      <h1 className="text-center">Are you sure want to Delete?</h1>
      <div className="flex w-full px-4 py-4">
        <div className="mb-6 w-full px-4 py-4">
          <Button
            color="primary"
            size="small"
            variant="solid"
            type="submit"
            className="w-full !px-10"
            onClick={() => proceed?.(false)}
          >
            No
          </Button>
        </div>

        <div className="mb-6 w-full px-4 py-4">
          <Button
            color="primary"
            size="small"
            variant="solid"
            type="submit"
            className="w-full !px-10"
            onClick={() => proceed?.(true)}
          >
            {isLoading ?
              <Spinner
                className={spinnerClasses}
              />
              :
              "Yes"
            }
            </Button>
        </div>
      </div>
    </>
  );
};

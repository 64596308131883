import { Button, Select, Spinner, Typography } from "../../../Atoms";
import { FormInput as Input } from "../..";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { dataForm, version } from "./utils";
import { AddEnvironmentModalProps } from "./interface";
import { getSpinnerSize, getSpinnerStokeColor } from "../../../Atoms/Button/utils";
export const AddGiftsCategoryModal = ({ save, categoryData, isLoading }: AddEnvironmentModalProps) => {
  const [initialData, setInitialData] = useState<{ name: string } | null>(null);
  const {
    handleSubmit,
    control,
    register,
    setValue,
    reset,
  } = useForm();

  useEffect(() => {
    if (categoryData && categoryData.length > 0) {
      const [firstCategory] = categoryData;
      setInitialData({ name: firstCategory.categoryName });
      reset({ name: firstCategory.categoryName });
    } else {
      setInitialData(null);
    }
  }, [categoryData, reset]);

  const onSubmit = (data: any) => {
    const payload = {
      categoryName: data.name
    }
    save?.(dataForm(payload))

  };
  const labelTextClassName = [
    "mb-1 font-semibold text-neutral-100 dark:text-neutral-600",
  ].join(" ");

  const spinnerClasses = [
    getSpinnerStokeColor("neon"),
    getSpinnerSize("small"),
  ].join(" ");

  return (
    <form
      className="md:!w-[650px] max-h-[500px] overflow-auto overflow-y-auto overflow-x-hidden"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="mb-6 px-4 ">
        <Input
          required
          className="w-full dark:text-neutral-400"
          control={control}
          label="Gift Name"
          placeholder="name"
          name="name"
          type="text"
          maxLength={20}
          defaultValue={initialData?.name || ""}
        />
      </div>
      <div className="mb-6 w-full flex justify-end px-4 py-4">
        <Button
          color="primary"
          size="small"
          variant="solid"
          type="submit"
          className="w-[200px] !px-10 !bg-[#3b7ddd]"
        >
          {isLoading ?
            <Spinner
              className={spinnerClasses}
            />
            :
            "Save change"
          }
        </Button>
      </div>
    </form>
  );
};

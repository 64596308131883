export const tableData = [
  "name",
  "categoryName",
  "giftPrice",
  "giftIcon",
  "giftImage",
  "createdAt",
  "edit",
  "delete",
  "id",
];
export const tableCategoryData = [
  // "giftName",
  "name",
  // "giftPrice",
  // "giftIcon",
  // "giftImage",
  "createdAt",
  "delete",
  "id",
];
export const tableHeaderRow = [
  "Name",
  "Category",
  "Price",
  "Icon",
  "Image",
  "Created At",
  "Edit",
  "Delete",
];
export const tableCategoryHeaderRow = [
  // "Gift Name",
  "Gift Category Name",
  // "Gift Price",
  // "Gift Icon",
  // "Gift Image",
  "Created At",
  "Delete",
];

import { useEffect, useMemo, useState } from 'react';
import { Navbar, Table } from '../index';
import { tableCategoryData, tableCategoryHeaderRow, tableData, tableHeaderRow } from './utils';
import { settingOptions } from '../../../utils';
import { useReactions } from '../../../hooks';
import { useGifts } from '../../../hooks/api/useGifts'
import { FilteredTableData, convertTimeZone } from '../../../utils/helper';
import { Button, RefetchIcon, useModal } from '../../Atoms';
import { AddGiftsModal, TableHeader } from '../../Molecules';
import { Spinner } from 'flowbite-react';
import { DeleteModal } from '../../Molecules/Models/deleteModal';
import { AddGiftsCategoryModal } from '../../Molecules/Models/addGiftsCategoryModal';
import { toast } from 'react-toastify';
export const GiftsHeader = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [entries, setEntries] = useState(10);
  const [giftListData, setGiftListData] = useState<any>([]);
  // const { reactionsAsync, addReactionsAsync, reactionsDeleteAsync } = useReactions();
  const { giftCategoryAsync,
    addGiftCategoryAsync,
    deleteGiftCategoryAsync,
    getGiftsListAsync,
    addGiftsListAsync,
    updateGiftsListAsync,
    deleteGiftsListAync } = useGifts()
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [isTrue, setIsTrue] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [showCategory, setShowCategory] = useState(false)
  const [rowData, setRowData] = useState<{ id: number; value: boolean }>({
    id: 0,
    value: false,
  });
  const [giftCategoryData, setGiftCategoryData] = useState([])
  const [editData, setEditData] = useState<any>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [deleteGift, setDeleteGift] = useState(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getGiftListData = async (data: number, entries: number) => {
    try {
      return await getGiftsListAsync(data, entries);
    } catch (error) { }
  };

  const getGiftCategory = async (data: number, entries: number) => {
    try {
      return await giftCategoryAsync(data, entries)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSearch = (e: string) => {
    setSearchTerm(e);
  };
  const handleEntries = (e: number) => {
    setEntries(e);
    setPageNumber(1);
  };

  const handleGiftDataList = async (pageNumber: number) => {
    try {
      setLoading(true);
      setIsTrue(false);
      const data = await getGiftListData(pageNumber, entries);
      if (data && data.success) {
        setLoading(false);
        setTotalDataCount(data?.data.count);
        setPageNumber(pageNumber);
        let filteredData = await FilteredTableData(data?.data?.list, tableData);

        if (searchTerm) {
          filteredData = filteredData.filter((item) =>
            Object.values(item).some((val) =>
              String(val).toLowerCase().includes(searchTerm.toLowerCase())
            )
          );
        }

        filteredData.forEach((item) => {
          item.createdAt = convertTimeZone(item.createdAt);
          item.edit = (
            <div className='flex justify-center'>
              <Button onClick={() => {
                editGiftList(item)
                setIsEditing(true)
              }}
                className='bg-blue-600'>
                Edit
              </Button>
            </div>
          )
          item.delete = (
            <div className='flex justify-center'>
              <Button onClick={() => deleteData(item.id)} className='bg-blue-600'>
                Delete
              </Button>
            </div>
          );
        });

        setGiftListData(filteredData);
        setIsTrue(true);
      } else {
        setLoading(false)
        setGiftListData([])
        setTotalDataCount(0)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  };

  const handleGiftCategoryList = async (pageNumber: number) => {
    try {
      setLoading(true);
      setIsTrue(false);
      const data = await getGiftCategory(pageNumber, entries);
      if (data && data.success) {
        setLoading(false);
        setTotalDataCount(data && data?.data && data?.data?.count);
        setPageNumber(pageNumber);
        const filteredData: any = await FilteredTableData(data && data?.data && data?.data?.list, tableCategoryData);
        filteredData.forEach((item: any) => {
          item.createdAt = convertTimeZone(item.createdAt);
          item.delete = (
            <div className='flex justify-center'>
              <Button onClick={() => deleteGiftCategory(item.id)} className='bg-blue-600'>
                Delete
              </Button>
            </div>
          );
        });
        setGiftCategoryData(filteredData);
        setIsTrue(true);
      } else {
        setLoading(false)
        setGiftCategoryData([])
        setTotalDataCount(0)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  };

  useEffect(() => {
    if (showCategory) {
      handleGiftCategoryList(pageNumber)
    }
  }, [showCategory, pageNumber, entries, searchTerm])

  const handlDelete = async (id: number) => {
    try {
      if (deleteGift) {
        setLoading(true)
        const data = await deleteGiftsListAync(id);
        if (data && data.success) {
          await handleGiftDataList(pageNumber);
          proceedModalCloseDelete();
          setLoading(false)
        }
      } else {
        setLoading(true)
        const data = await deleteGiftCategoryAsync(id);
        if (data && data.success) {
          await handleGiftCategoryList(pageNumber);
          proceedModalCloseDelete();
          setLoading(false)
        }
      }
    } catch (error) {
      console.log('err', error);
      setLoading(false)
    }
  };

  const handleProceedDelete = (isConfirmed: boolean) => {
    if (!isConfirmed) {
      return proceedModalCloseDelete();
    }
    handlDelete(rowData.id);
  };

  const deleteData = (id: number) => {
    proceedModalOpenDelete();
    setRowData((prev) => ({
      ...prev,
      id,
    }));
    setDeleteGift(true)
  };

  const deleteGiftCategory = (id: number) => {
    proceedModalOpenDelete();
    setDeleteGift(false)
    setRowData((prev) => ({
      ...prev,
      id,
    }));
  };

  const editGiftList = (editData: any) => {
    openGiftModal()
    setEditData(editData)
  }

  useMemo(async () => {
    handleGiftDataList(pageNumber);
  }, [pageNumber, entries, searchTerm]);

  const handleGiftListData = async (data: FormData) => {
    try {
      if (!isEditing) {
        try {
          setLoading(true)
          const res = await addGiftsListAsync?.(data);
          if(res && res.success) {
            setLoading(false)
            handleGiftDataList(pageNumber);
            close();
          } else {
            setLoading(false)
            toast.error("Something went wrong!")
          }
        } catch (error) {
          console.log(error)
          setLoading(false)
          toast.error("Something went wrong!")
        }
      } else {
        try {
          setLoading(true)
          const response = await updateGiftsListAsync?.(data, editData?.id);
          if(response && response.success) {
            setLoading(false)
            setIsEditing(false)
            handleGiftDataList(pageNumber);
            close();
          } else {
            setLoading(false)
            toast.error("Something went wrong!")
          }
        } catch (error) {
          console.log(error)
          setLoading(false)
          toast.error("Something went wrong!")
        }
      }
    } catch (error) { }
  };

  const handleGiftCategory = async (data: FormData) => {
    try {
      setLoading(true)
      const res = await addGiftCategoryAsync?.(data);
      if (res && res.success) {
        setLoading(false)
        handleGiftCategoryList(pageNumber);
        closeCategoryModal();
      } else {
        setLoading(false)
        toast.error("Something went wrong!")
      }
    } catch (error) { 
      console.log(error)
      setLoading(false)
      toast.error("Something went wrong!")
    }
  };

  const handleShowGiftCategory = () => {
    setShowCategory(!showCategory)
  }

  useEffect(() => {
    setShowCategory(false)
  }, [])

  const openGiftAddModel = () => {
    openGiftModal()
    setIsEditing(false)
  }

  const { Modal: AddModal, open: openGiftModal, close } = useModal(<AddGiftsModal giftListData={giftListData} editData={editData} isEditing={isEditing} setIsEditing={setIsEditing} isLoading={isLoading} save={handleGiftListData} />, 'Add Gifts');
  const { Modal: AddCategoryModal, open: openCategoryModal, close: closeCategoryModal } = useModal(<AddGiftsCategoryModal isLoading={isLoading} save={handleGiftCategory} />, 'Add Category');
  const {
    Modal: AddProceedDeleteModal,
    open: proceedModalOpenDelete,
    close: proceedModalCloseDelete,
  } = useModal(<DeleteModal proceed={handleProceedDelete} isLoading={isLoading} />, '');
  // const { Modal: EditGiftModel, open: openEditGift, close: closeEditGift } = useModal(<AddGiftsCategoryModal categoryData={editData} save={handleGiftListData} />, 'Add Category');
  return (
    <main className='flex-grow w-full h-[264px] bg-blue-900 p-6'>
      <Navbar options={settingOptions} />
      <div className='bg-white p-4 shadow-md '>
        <div className='justify-between flex'>
          {/* <div>
            <p className="text-[16px] mb-3">reactions</p>
          </div> */}
          <div className='flex justify-between gap-2' style={{ marginLeft: 'auto' }}>
            <button onClick={() => !showCategory ? handleGiftDataList(pageNumber) : handleGiftCategoryList(pageNumber)}>{!isTrue ? <Spinner /> : <RefetchIcon />}</button>
            <Button onClick={handleShowGiftCategory} color='primary' size='small' className='w-full !px-10 !bg-[#3B7DDD] mb-2'>
              {showCategory ? "Gift List" : "Categories List"}
            </Button>
            <Button onClick={openCategoryModal} color='primary' size='small' className='w-full !px-10 !bg-[#3B7DDD] mb-2'>
              Add catagories
            </Button>
            <Button onClick={() => openGiftAddModel()} color='primary' size='small' className='w-full !px-10 !bg-[#3B7DDD] mb-2'>
              Add gifts
            </Button>
          </div>
        </div>
        <TableHeader onSearch={handleSearch} onChangeEntries={handleEntries} />
        {!showCategory ?
          <Table
            tableDate={giftListData}
            searchItem={searchTerm}
            entries={entries}
            keysToFilter={tableData}
            totalDataCount={totalDataCount}
            pageNumber={pageNumber}
            onPageChange={handleGiftDataList}
            tableHeaderRow={tableHeaderRow}
            isLoading={isLoading}
            className="gifts_table_wrp"
          />
          :
          <Table
            tableDate={giftCategoryData}
            searchItem={searchTerm}
            entries={entries}
            keysToFilter={tableCategoryData}
            totalDataCount={totalDataCount}
            pageNumber={pageNumber}
            onPageChange={handleGiftCategoryList}
            tableHeaderRow={tableCategoryHeaderRow}
            isLoading={isLoading}
          />
        }
        <AddCategoryModal />
        <AddModal />
        <AddProceedDeleteModal />
        {/* <EditGiftModel /> */}
      </div>
    </main>
  );
};

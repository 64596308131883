import { createSlice } from "@reduxjs/toolkit";

export interface CounterStateArr {
  value: any[];
  heading: string;
  uploadGiftIcon: any
  uploadGiftImage: any
}

const initialState: CounterStateArr = {
  value: [],
  heading: "World",
  uploadGiftIcon: null,
  uploadGiftImage: null
};

export const analyticsSideBarSlice = createSlice({
  name: "analyticsSidebar",
  initialState,
  reducers: {
    analyticsFilterHistory: (state, data) => {
      state.value = [...state.value, data];
    },
    analyticsFilterValue: (state, action) => {
      state.heading = action?.payload;
    },
    setUploadGiftIcon: (state, action) => {
      state.uploadGiftIcon = action?.payload;
    },
    setUploadGiftImage: (state, action) => {
      state.uploadGiftImage = action?.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { analyticsFilterHistory, analyticsFilterValue, setUploadGiftIcon, setUploadGiftImage } =
  analyticsSideBarSlice.actions;

export default analyticsSideBarSlice.reducer;

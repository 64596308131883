import { GiftsService } from "../../api-services";

export const useGifts = () => {
  const giftCategoryAsync = (pageSize: number, pageNumber: number) =>
    GiftsService.getGiftsCategory(pageSize, pageNumber);
  const addGiftCategoryAsync = (data: FormData) =>
    GiftsService.addGiftsCategory(data);
  const deleteGiftCategoryAsync = (giftcategoryId: number) =>
    GiftsService.deleteGiftsCategory(giftcategoryId);
  const getGiftsListAsync = (pageSize: number, pageNumber: number) =>
    GiftsService.getGiftsList(pageSize, pageNumber);
  const addGiftsListAsync = (data: FormData) =>
    GiftsService.addGiftsList(data);
  const updateGiftsListAsync = (data: FormData, giftId: number) =>
    GiftsService.updateGiftsList(data, giftId);
  const deleteGiftsListAync = (giftId: number) =>
    GiftsService.deleteGiftsList(giftId);

  return {
    giftCategoryAsync,
    addGiftCategoryAsync,
    deleteGiftCategoryAsync,
    getGiftsListAsync,
    addGiftsListAsync,
    updateGiftsListAsync,
    deleteGiftsListAync
  };
};
